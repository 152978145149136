import './auth-module.scss';

import * as Yup from 'yup';

import React, { useCallback, useEffect, useState } from 'react';
import { getUserByToken, login, refreshAccessTokenPostRequest } from '../core/_request';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Cookies from 'universal-cookie';
import { ENUM_API_STATUS } from '@/utils/_gConstant';
import Loader from '../../common/Loader';
import LogoImage from '@/components/common/LogoImage';
import { fetchAuthUserSuccess } from '../state/_action';
import loginBanner from '../../../assets/img/home-owner-banner.svg';
import siteConfig from '../../../services/_siteConfig';
import { useAuth } from '../core/Auth';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Falsches E-Mail-Format')
    .min(3, 'Mindestens 3 Zeichen')
    .max(50, 'Mindestens 50 Zeichen')
    .required('E-Mail ist erforderlich'),
  password: Yup.string()
    .min(3, 'Mindestens 3 Zeichen')
    .max(50, 'Mindestens 50 Zeichen')
    .required('Passwort wird benötigt'),
});

const initialValues = {
  email: '',
  password: '',
};

const Login = (props) => {
  const host = window.location.host;
  const [slug] = host.split('.', 1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const [loading, setLoading] = useState(false);
  const [showLoginUI, setShowLoginUI] = useState(true);
  const { saveAuth, setCurrentUser } = useAuth();
  const [params, setSearchParams] = useSearchParams();

  const forwardToUrl = useCallback((access, refresh, user) => {
    const parsedUrl = new URL(user?.dashboard);
    const hostname = parsedUrl.hostname;
    const path = parsedUrl.pathname;

    
    
    if (hostname === document.location.hostname) {
      saveAuth(access);
      localStorage.setItem(siteConfig.REFRESH_TOKEN, refresh);
      setCurrentUser(user);
      if (user?.id) {
        dispatch(fetchAuthUserSuccess(user));
      }
      cookies.set('dashboard', user?.dashboard, {
        domain: process.env.REACT_APP_DOMAIN,
        path: '/',
      });
      cookies.set('userName', user?.first_name, {
        domain: process.env.REACT_APP_DOMAIN,
        path: '/',
      });
      navigate(path);
    } else {
      window.location.href = user?.dashboard;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAutoLogin = async (refreshToken) => {
    try {
      const res = await refreshAccessTokenPostRequest(refreshToken);
      if (res?.status === ENUM_API_STATUS?.SUCCESS_POST_API_200) {
        const userRes = await getUserByToken(res?.data?.access);
        const user = userRes?.data;
        forwardToUrl(res?.data?.access, refreshToken, user);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    document.title = props.title;
    const refreshToken = params?.get('auth');
    if (refreshToken) {
      setShowLoginUI(false);
      setSearchParams((prevParams) => {
        prevParams.delete('auth');
        return prevParams;
      });
      handleAutoLogin(refreshToken);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{

    const accessToken = localStorage.getItem(siteConfig.ACCESS_TOKEN);
    if (accessToken) {
      (async () => {
        setLoading(true);
        try {
          const { data: user } = await getUserByToken(accessToken);
          setLoading(false);
          const refresh = localStorage.getItem(siteConfig.REFRESH_TOKEN);
          forwardToUrl(accessToken, refresh, user);
        } catch (error) {
          saveAuth(undefined);
          localStorage.removeItem(siteConfig.ACCESS_TOKEN);
          localStorage.removeItem(siteConfig.REFRESH_TOKEN);
        } finally {
          setLoading(false);
        }
      })();
    }

  },[]);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { data: auth } = await login(values.email, values.password);
        const { data: user } = await getUserByToken(auth?.access);
        setLoading(false);
        forwardToUrl(auth?.access, auth?.refresh, user);
      } catch (error) {
        saveAuth(undefined);
        setStatus('An error occurred during login');
        setSubmitting(false);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <section className='auth-module'>
      {showLoginUI && (
        <div className='auth-module__container'>
          <div className='auth-module__main'>
            <div className='logo-container'>
              <LogoImage slug={slug} />
            </div>
            <form className='auth-module__form' onSubmit={formik.handleSubmit}>
              <h1 className='text-48 font-medium'>Anmeldung</h1>
              {slug !== 'app' ? (
                <p className='secondary-font text-secondary text-lg leading4 mt-2'>
                  Bitte geben Sie Ihre E-Mail-Adresse und Passwort ein, um sich anzumelden.
                </p>
              ) : (
                <p className='secondary-font text-secondary text-lg leading4 mt-2'>
                  Bitte gib deine E-Mail-Adresse und dein Passwort ein, um dich anzumelden.
                </p>
              )}

              <div className='mt-8'>
                <div className='input-field-wrapper'>
                  <input
                    type='email'
                    id='email'
                    name='email'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='input-field input-field--h52 input-field--md font-medium lato'
                    placeholder='Email'
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className='error-message'>{formik.errors.email}</div>
                  ) : null}
                </div>
                <div className='input-field-wrapper'>
                  <input
                    type='password'
                    id='password'
                    name='password'
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='input-field input-field--h52 input-field--md font-medium lato'
                    placeholder='Password'
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className='error-message'>{formik.errors.password}</div>
                  ) : null}
                </div>
                <button
                  className='btn btn--primary btn--h52 px-6 text-base w-full mt-12 relative'
                  disabled={loading}
                >
                  {' '}
                  {loading ? <Loader white size='xs' /> : 'Anmeldung'}
                </button>

                <div className='text-center mt-4'>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    className='inline-block secondary-font color-grey2 font-medium text-underline cursor-pointer'
                    onClick={() => navigate('/reset-password-email')}
                  >
                    Passwort vergessen
                  </a>
                </div>
              </div>
            </form>
          </div>
          <div className='auth-module__banner'>
            <div className='w-full h-full rounded-2xl'>
              <img
                src={loginBanner}
                alt='banner'
                className='w-full h-full object-cover rounded-2xl'
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Login;
