import { Route, Routes } from 'react-router-dom';

import AdminRoute from './AdminRoute';
import ConsultantRoute from './ConsultantRoute';
import DPRoutes from './DPRoutes';
import DistributionPartnerRegistration from '@/components/auth/component/DistributionPartnerRegistration';
import HomeOwnerRoutes from './HomeOwnerRoutes';
import PropTypes from 'prop-types';
import SurveyRoutes from './SurveyRoutes';

const SlugRoutes = () => {
  const host = window.location.host;
  const [slug] = host.split('.', 1);

  if (slug !== process.env.REACT_APP_DP_URL) {
    return (
      <Routes>
        <Route path='/dp/*' element={<DPRoutes />} />
      </Routes>
    );
  }
  return (
    <>
    <Routes>
      <Route path='/dp-registration' element={<DistributionPartnerRegistration />} />
      <Route
        path='/survey/:leadId/:questionIndex'
        element={<SurveyRoutes key={window.location.pathname} />}
      />
      <Route path='/homeowner/:leadId/*' element={<HomeOwnerRoutes />} />
      <Route path='/admin/*' element={<AdminRoute />} />
      <Route path='/consultant/*' element={<ConsultantRoute />} />
    </Routes>
    </>
  );
};

SlugRoutes.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.node,
    PropTypes.object,
  ]).isRequired,
};

export default SlugRoutes;
