import '../../assets/styles/scss/pages/admin-dashboard.scss';

import {
  DPuserRoles,
  ENUM_API_STATUS,
  ENUM_LEAD_CREATED_FROM,
  buildingTypeOptions,
  leadStageOptions,
} from '@/utils/_gConstant';
import React, { useCallback, useEffect, useState } from 'react';
import {
  resetData,
  setBillingCity,
  setBillingCompanyName,
  setBillingCountry,
  setBillingFirstName,
  setBillingHouseNumber,
  setBillingLastName,
  setBillingPostalCode,
  setBillingStreet,
  setBuildingApplication,
  setCity,
  setClientType,
  setCompanyName,
  setEmail,
  setFirstName,
  setHouseNumber,
  setLastName,
  setPhone,
  setPostalCode,
  setStreet,
} from '../DistributionPartner/store/_actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import ActivityHistory from './ActivityHistory';
import AddEditLead from './AddEditLead';
import Document from './Document';
import FileIcon from "../../components/dashboard/icon/FileIcon";
import FormAnswers from './FormAnswers';
import Header from '../DistributionPartner/components/Header';
import LeadInfo from './LeadInfo';
import Notes from './Notes';
import OrderSummary from '../DistributionPartner/components/OrderSummary';
import arrowLeft from '../../assets/icons/arrow-left.svg';
import editWhite from '../../assets/icons/edit-white.svg';
import emailIcon from '../../assets/icons/email-white.svg';
import { formatDate } from '@/utils/_gFunctions/_handleDateFormat';
import phoneIcon from '../../assets/icons/phone-white.svg';
import { useTranslation } from 'react-i18next';

import { LeadListViewGetRequest, getListDetailsGetRequest, stageChangeapi } from './_request';
import { toastError, toastSuccess } from "../../components/ui-elements/_Toastify";
import close from "../../assets/icons/close.svg";

const setPropertyAddress = (dispatch, address) => {
  const obj = {
    street: setStreet,
    house_number: setHouseNumber,
    city: setCity,
    zip_code: setPostalCode,
    building_application: setBuildingApplication,
  };

  for (const [key, setter] of Object.entries(obj)) {
    dispatch(setter(address[key]));
  }
};
const setBillingAddress = (dispatch, address) => {
  const obj = {
    first_name: setBillingFirstName,
    last_name: setBillingLastName,
    company: setBillingCompanyName,
    street: setBillingStreet,
    house_number: setBillingHouseNumber,
    zip_code: setBillingPostalCode,
    city: setBillingCity,
    country: setBillingCountry,
  };
  for (const [key, setter] of Object.entries(obj)) {
    dispatch(setter(address[key]));
  }
};

const LeadDetails = (props) => {
  const { t } = useTranslation();
  const { editIcon } = props;
  const dispatch = useDispatch();
  const _guserRole = useSelector((state) => state?.authReducer?.user?.role);
  const leadId = useParams()?.leadId;
  const navigate = useNavigate();
  const [leadData, setLeadData] = useState({});
  const [activeTab, setActiveTab] = useState(t('tabs.leadInfo'));
  const [editModal, setEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [fileName, setFileName] = useState("");
  const [setLoader] = useState(false);

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  const getLeadData = async () => {
    const res = await getListDetailsGetRequest(leadId);
    if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
      setLeadData(res?.data);
      dispatch(setCompanyName(res?.data?.company_name));
      dispatch(setFirstName(res?.data?.first_name));
      dispatch(setLastName(res?.data?.last_name));
      dispatch(setPhone(res?.data?.mobile_number));
      dispatch(setEmail(res?.data?.email));
      dispatch(setClientType(res?.data.client_type));
      setPropertyAddress(dispatch, res?.data?.property_address);
      setBillingAddress(dispatch, res?.data?.billing_address);
      setIsLoading(false);
    }
  };

  const getleads = useCallback((page, reset = false, searchd) => {
    // setLoader(true)
    if (page !== null) {
      LeadListViewGetRequest({ page, page_size: 20, search: searchd }, true)
        .then((res) => {
          if (res?.status === ENUM_API_STATUS.SUCCESS_GET_API) {
            const updatedResults = res.data?.results;
            const newData = updatedResults.find((it, index) => it.id === leadId);
            setLeadData(newData);
          }
        })
        .catch((err) => {
          console.log("Erredreir", err);
        });
    }
  }, [leadId]);

  useEffect(() => {
    document.title = props.title;
    if (leadId) {
      getLeadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.title]);

  const handleEditLeadModal = (data) => {
    setEditModal(data);
  };

  const updateLead = useCallback(
    (leadData) => {
      setLeadData(leadData);
    },
    [setLeadData]
  );

  const goBack = () => {
    navigate(-1);
    if (!editIcon) {
      dispatch(resetData());
    }
  };

  const handleStageChange = useCallback(
    async (item, newStage) => {
      if (newStage === 5) {
        setShow(item);
      } else {
        setLoader(true)
        const data = {
          lead_id: item?.id,
          stage: newStage,
        };
        stageChangeapi(data)
          .then((res) => {
            if (res.status === ENUM_API_STATUS.SUCESS_POST_API_200) {
              toastSuccess(res.data.message);
              // setSelectedStages((prev) => ({ ...prev, [item.enq_id]: newStage }));

              getleads(1, true, "");
            } else {
              toastError(res.data);
            }
          })
          .catch((err) => {
            toastError(err);
          }).finally(()=>{
            setLoader(false)
          });
      }
    },
    [getleads, setLoader]
  );

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      const fileSize = file.size;

      // Check if the file is an image
      if (fileType.startsWith("image/")) {
        setErrorMessage(
          "Images are not allowed. Please upload other file types."
        );
        setFileName("");
        return;
      }

      // Check if the file size is not more than 10MB
      if (fileSize > 10 * 1024 * 1024) {
        setErrorMessage("File size should not exceed 10MB.");
        setFileName("");
        return;
      }

      // If both checks pass, reset error message and set the file name
      setErrorMessage("");
      setFileName(file);
    }
  };

  const handleupload = () => {
    if (!fileName) {
      setErrorMessage("Please upload a file.");
      return;
    }
    setLoader(true)
    const formdata = new FormData();
    formdata.append("lead_id", show?.id);
    formdata.append("stage", 5);
    formdata.append("isfp_document", fileName);
    stageChangeapi(formdata)
      .then((res) => {
        if (res.status === ENUM_API_STATUS.SUCESS_POST_API_200) {
          toastSuccess(res.data.message);
          getleads(1, true, "");
          setShow(false);
          setFileName("");
          setErrorMessage("");
        } else {
          toastError(res.data);
        }
      })
      .catch((err) => {
        toastError(err);
      }).finally(()=>{
        setLoader(false)
      })
  };
  const handleClose = () => {
    setShow(false);
    setFileName("");
    setErrorMessage("");
  };

  return (
    <>
      {!editIcon && <Header />}
      {isLoading && (
        <div className='loader-wrapper'>
          <div className='loader'></div>
        </div>
      )}
      {!isLoading && (
        <>
          <section className='admin-dashboard-wrapper'>
            <main className='admin-dashboard__main pb-8 pt-5 px-10' style={{ paddingTop: 88 }}>
              <div className='flex items-center'>
                <div className='breadcrumb__back-icon' onClick={() => goBack()}>
                  <img src={arrowLeft} alt='arrow-left' />
                </div>
                <div className='breadcrumb'>
                  <div className='breadcrumb__list'>{t('breadcrumb.leads')}</div>
                  <div className='breadcrumb__list'>{leadData?.first_name}</div>
                </div>
              </div>
              <div className='flex -mx-4 mt-4 lead_main_box'>
                <div className='lead-brief-card px-4'>
                  <div className='lead-brief-card__header'>
                    <div className='flex items-center justify-between'>
                      <h1 className='text-36 font-semibold' style={{ color: '#F2F0E4' }}>
                        {leadData?.first_name}
                      </h1>
                      {editIcon && (
                        <div
                          className='flex items-center justify-center cursor-pointer'
                          onClick={handleEditLeadModal}
                        >
                          <img src={editWhite} alt='edit-white' />
                        </div>
                      )}
                    </div>
                    <p className='text-white opacity8 mt-2'>
                      {t(
                        `${
                          buildingTypeOptions?.find(
                            (option) => option?.value === leadData?.building_type
                          )?.label
                        }`
                      )}
                    </p>
                    <select
                      className="tags mt-2 p-2 rounded"
                      value={leadData?.stages}
                      onChange={(e) =>
                        handleStageChange(leadData, e.target.value)
                      }
                      style={{backgroundColor:"white",color:"black" , border:'1px solid white' }}
                    >
                      {leadStageOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div className='mt-4'>
                      <div className='flex items-center'>
                        <div className='flex items-center flex-shrink-0 w-5 h-5'>
                          <img src={emailIcon} alt='email' />
                        </div>
                        <span className='secondary-font text-white opacity7 ml-4'>
                          {leadData?.email?.length > 30
                            ? `${leadData?.email.slice(0, 30)}...`
                            : leadData?.email}
                        </span>
                      </div>
                      <div className='flex items-center mt-3'>
                        <div className='flex items-center flex-shrink-0 w-5 h-5'>
                          <img src={phoneIcon} alt='phone' />
                        </div>
                        <span className='secondary-font text-white opacity7 ml-4'>
                          {leadData?.mobile_number}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='lead-brief-card__body'>
                    <div className='pb-4' style={{ borderBottom: '1px solid #d1d5db' }}>
                      <div className='flex items-center justify-between flex-wrap -mx-4 mb-2 mb-not-last-child'>
                        <label className='col-6 px-4 secondary-font text-secondary text-sm font-medium'>
                          {t('breadcrumb.enquiryId')}
                        </label>
                        <span className='col-6 px-4 secondary-font font-semibold text-right'>
                          {leadData?.enq_id}
                        </span>
                      </div>
                      <div className='flex items-center justify-between flex-wrap -mx-4 mb-2 mb-not-last-child'>
                        <label className='col-6 px-4 secondary-font text-secondary text-sm font-medium'>
                          {t('breadcrumb.enquiryDate')}
                        </label>
                        <span className='col-6 px-4 secondary-font font-semibold text-right'>
                          {formatDate(leadData?.enq_date)}
                        </span>
                      </div>
                      {_guserRole === DPuserRoles?.DP_ADMIN && (
                        <div className='flex items-center justify-between flex-wrap -mx-4 mb-2 mb-not-last-child'>
                          <label className='col-6 px-4 secondary-font text-secondary text-sm font-medium'>
                            {t('breadcrumb.consultantName')}
                          </label>
                          <span className='col-6 px-4 secondary-font font-semibold text-right'>
                            {leadData?.consultant_assigned?.first_name ||
                              t('breadcrumb.notAssigned')}
                          </span>
                        </div>
                      )}
                      <div className='flex items-center justify-between flex-wrap -mx-4 mb-2 mb-not-last-child'>
                        <label className='col-6 px-4 secondary-font text-secondary text-sm font-medium'>
                          {t('breadcrumb.country')}
                        </label>
                        <span className='col-6 px-4 secondary-font font-semibold text-right'>
                          {leadData?.country}
                        </span>
                      </div>
                      <div className='flex items-center justify-between flex-wrap -mx-4 mb-2 mb-not-last-child'>
                        <label className='col-6 px-4 secondary-font text-secondary text-sm font-medium'>
                          {t('breadcrumb.city')}
                        </label>
                        <span className='col-6 px-4 secondary-font font-semibold text-right'>
                          {leadData?.city}
                        </span>
                      </div>
                      <div className='flex items-center justify-between flex-wrap -mx-4 mb-2 mb-not-last-child'>
                        <label className='col-6 px-4 secondary-font text-secondary text-sm font-medium'>
                          {t('breadcrumb.postcode')}
                        </label>
                        <span className='col-6 px-4 secondary-font font-semibold text-right'>
                          {leadData?.postal_code}
                        </span>
                      </div>
                    </div>
                    <div className='tags mt-3'>
                      {leadData?.tags?.map((tag) => (
                        <div className='tags__list'>
                          {tag.length > 30 ? `${tag.slice(0, 30)}...` : tag}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* <div className="px-4 w-full"> */}
                  <div className='lead-main-info px-4'>
                    <div className='tab-btn-wrapper'>
                      <input
                        type='radio'
                        id='tabBtn1'
                        className='tab-btn__input'
                        name='tab-btn'
                        checked={activeTab === t('tabs.leadInfo')}
                        onChange={() => handleTabChange(t('tabs.leadInfo'))}
                      />
                      <label htmlFor='tabBtn1' className='tab-btn'>
                        {t('tabs.leadInfo')}
                      </label>
                      <input
                        type='radio'
                        id='tabBtn2'
                        className='tab-btn__input'
                        name='tab-btn'
                        checked={activeTab === t('tabs.formAnswers')}
                        onChange={() => handleTabChange(t('tabs.formAnswers'))}
                      />
                      <label htmlFor='tabBtn2' className='tab-btn'>
                        {t('tabs.formAnswers')}
                      </label>
                      <input
                        type='radio'
                        id='tabBtn3'
                        className='tab-btn__input'
                        name='tab-btn'
                        checked={activeTab === t('tabs.document')}
                        onChange={() => handleTabChange(t('tabs.document'))}
                      />
                      <label htmlFor='tabBtn3' className='tab-btn'>
                        {t('tabs.document')}
                      </label>
                      <input
                        type='radio'
                        id='tabBtn4'
                        className='tab-btn__input'
                        name='tab-btn'
                        checked={activeTab === t('tabs.activityHistory')}
                        onChange={() => handleTabChange(t('tabs.activityHistory'))}
                      />
                      {/* <label htmlFor='tabBtn4' className='tab-btn'>
                        {t('tabs.activityHistory')}
                      </label> */}
                      <input
                        type='radio'
                        id='tabBtn5'
                        className='tab-btn__input'
                        name='tab-btn'
                        checked={activeTab === t('tabs.notes')}
                        onChange={() => handleTabChange(t('tabs.notes'))}
                      />
                      {/* <label htmlFor='tabBtn5' className='tab-btn'>
                        {t('tabs.notes')}
                      </label> */}
                      {leadData?.created_from?.type === ENUM_LEAD_CREATED_FROM?.DISTRIBUTOR && (
                        <>
                          <input
                            type='radio'
                            id='tabBtn6'
                            className='tab-btn__input'
                            name='tab-btn'
                            checked={activeTab === t('tabs.orderSummary')}
                            onChange={() => handleTabChange(t('tabs.orderSummary'))}
                          />
                          <label htmlFor='tabBtn6' className='tab-btn'>
                            {t('tabs.orderSummary')}
                          </label>
                        </>
                      )}
                    </div>
                    <div className='main_view-questions'>
                      {activeTab === t('tabs.orderSummary') && <OrderSummary />}
                      {activeTab === t('tabs.leadInfo') && <LeadInfo leadData={leadData} />}
                      {activeTab === t('tabs.formAnswers') && <FormAnswers leadData={leadData} />}
                      {activeTab === t('tabs.document') && <Document leadData={leadData} setLeadData={setLeadData} />}
                      {activeTab === t('tabs.activityHistory') && <ActivityHistory />}
                      {activeTab === t('tabs.notes') && <Notes />}
                    </div>
                  </div>
                {/* </div> */}
              </div>
            </main>
          </section>
          {editModal && (
            <AddEditLead
              onClose={handleEditLeadModal}
              modalData={{ lead: leadData }}
              refreshList={getLeadData}
              updateLead={updateLead}
            />
          )}
          {show && (
            <div className="modal modal--add-lead open">
              <div className="modal__container">
                <div className="modal__header">
                  <h1 className="text-lg font-bold">Please Upload File</h1>
                  <div className="modal__close" onClick={handleClose}>
                    <img src={close} alt="close" />
                  </div>
                </div>
                <div className="modal__body">
                  <div className="dropzonepdf">
                    <input
                      type="file"
                      accept="*/*"
                      name="file_uplod"
                      id="profileUploader"
                      onChange={handleFileInput}
                      className="uploadpdf"
                    />
                    <div className="icon_titlepdf">
                      <span className="icon_pdf">
                        <FileIcon />
                      </span>
                      <p id="filename" className="test_pdf">
                        {fileName?.name}
                      </p>
                      <span className="plane_text_pdf">Upload File</span>
                    </div>
                    <aside className="upload_pdf">
                      {/* {activeTab === index} */}
                    </aside>

                    {errorMessage && (
                      <div className={"animated fadeIn err-msg"}>
                        <div className="error">{errorMessage}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="modal__footer justify-end">
                  <button
                    type="button"
                    className="btn btn--secondary btn--h40 px-6 py-2 text-base font-semibold"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <button
                    id="sub"
                    form="sub"
                    type="submit"
                    className="btn btn--primary btn--h40 px-6 py-2 text-base font-semibold"
                    onClick={handleupload}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default LeadDetails;
